<div (resized)="evaluateMaxAvatarsToShow($event)" class="celum-avatar-list_wrap" resizeObserver>
  <ng-container *ngFor="let config of avatarsShortList">
    <ng-container
        [ngTemplateOutlet]="avatarTemplate"
        [ngTemplateOutletContext]="{ config }">
    </ng-container>
  </ng-container>

  <ng-container *ngIf="showMoreAvatarsIcon">
    <celum-avatar #trigger="cdkOverlayOrigin"
                  (click)="onClick($event)"
                  [class.celum-avatar-list_show-more]="showMoreAvatarsMenu"
                  [configuration]="showMoreAvatarsIcon"
                  data-test-show-more-avatars
                  cdkOverlayOrigin>
    </celum-avatar>

    <ng-template (backdropClick)="this.isShowMoreAvatarsMenuOpen = false" *ngIf="showMoreAvatarsMenu"
                 [cdkConnectedOverlayHasBackdrop]="true"
                 [cdkConnectedOverlayOpen]="isShowMoreAvatarsMenuOpen"
                 [cdkConnectedOverlayOrigin]="trigger"
                 [cdkConnectedOverlayPositions]="[
                   { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' },
                   { originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom' },
                   { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' },
                   { originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'bottom' },
                 ]"
                 cdkConnectedOverlayBackdropClass="remove-default-style"
                 cdkConnectedOverlay>

      <celum-avatar-list-menu
        class="celum-scrollbar--dark"
        data-test-celum-avatar-list_menu
        [avatarConfigs]="avatarConfigsForMenu"
        [paginationInfo]="paginationInfo"
        (loadMore)="loadMore.emit()">
      </celum-avatar-list-menu>

    </ng-template>
  </ng-container>
</div>


